import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";
import MultiFeature from "../components/bricks/MultiFeature";
import Strip from "../components/ui/Strip";
import ThemeBox from "../components/bricks/ThemeBox";
import {themeDark} from "../page-styles/landingpage.css";
import ThemedMultiFeature from "../components/bricks/ThemedMultiFeature";

const Podcast = ({location}) => {
  return (
    <DefaultLayout
      title="Game Production Podcast"
      description="The Game Production Podcast for and by Game Developers"
      size="sm"
      location={location}
      footer={null}
    >
      <Hero
        size="md"
        title="Game Production Podcast"
        subline="Conversations with game developers about the production and business side of game development. How to decide which game to work? How to create it? How to market it?"
        img="podcast.png"
      />
      <ThemeBox theme={themeDark}>
        <iframe
          width="100%"
          height="180"
          frameBorder="no"
          scrolling="no"
          seamless
          src="https://share.transistor.fm/e/868367b8"
          title="podcast player"
        />

        <ThemedMultiFeature
          maxColumns={1}
          features={[
            {
              lead: "Episode 5",
              title: "From Kingdom to Cloud Gardens with Thomas van den Berg of noio",
              titleUrl: "/blog/2021/game-production-podcast-ep5-noio/",
            },
            {
              lead: "Episode 4",
              title: "Indie Game Marketing Tricks with Philomena Schwab of Stray Fawn Studio",
              titleUrl: "/blog/2021/game-production-podcast-ep4-stray-fawn/",
            },
            {
              lead: "Episode 3",
              title: "Creating thought-provoking games with Ata Sergey Nowak from Torpor Games",
              titleUrl: "/blog/2021/game-production-podcast-ep3-torpor-games/",
            },
            {
              lead: "Episode 2",
              title: "How 11 Bit publishes games with Marek Ziemak from 11 Bit Studios",
              titleUrl: "/blog/2021/game-production-podcast-2-11-bit-studios/",
            },
            {
              lead: "Episode 1",
              title: "Networking for Game Devs with Daniel Helbig and Jan Bubenik from Megagon",
              titleUrl: "/blog/2020/game-production-podcast-1-megagon-industries/",
            },
          ]}
        />
      </ThemeBox>
    </DefaultLayout>
  );
};

export default Podcast;
